import React from 'react'
import Modal from 'react-modal'
import { createRoot } from 'react-dom/client'
import { createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import i18next from 'i18next'
import { QueryClientProvider } from '@tanstack/react-query'
import { locale as daysjsLocale } from 'dayjs'
import { Toaster } from 'react-hot-toast'

import PageWrapper from 'components/PageWrapper'
import RouteWatcher from 'components/RouteWatcher'
import { initSentry, wrappedCreateBrowserRouter } from 'utils/sentry'
import { initAppsignal } from 'utils/appsignal'
import { getDefaultQueryClient } from 'utils/react-query'
import ErrorPage from 'components/ErrorPage'
import useSetupHubSpotChat from './hooks/useSetupHubSpotChat'
import { AgentProvider } from './providers/useAgent'
import Login from './pages/Login'
import Home from './pages/Home'
import Skeleton from './pages/Skeleton'
import LoggedInRoutes from './pages/LoggedInRoutes'
import EmployeeList from './pages/EmployeeList'
import PublicRoutes from './pages/PublicRoutes'
import EmployeeProfile from './pages/EmployeeProfile'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import { AgencyProvider } from './contexts/AgencyContext'

Modal.setAppElement('#root')

const queryClient = getDefaultQueryClient()

initSentry('agency')
initAppsignal('agency')

const router = wrappedCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<RouteWatcher />} errorElement={<ErrorPage />}>
      <Route path="/" element={<Home />} />

      <Route
        element={
          <AgencyProvider>
            <LoggedInRoutes />
          </AgencyProvider>
        }
      >
        <Route element={<Skeleton />}>
          {/* TO BE REMOVED ONCE MULTI AGENCY IS DONE */}
          <Route
            path="/agency_employees"
            element={<PageWrapper title={i18next.t('agency.home.employee_list_title')} component={EmployeeList} />}
          />
          <Route
            path="/agency_employees/:agencyEmployeeId"
            element={<PageWrapper title={i18next.t('agency.employee_profile.title')} component={EmployeeProfile} />}
          />
          {/* TO BE REMOVED ONCE MULTI AGENCY IS DONE */}
          <Route
            path="/agencies/:agencyId/agency_employees"
            element={<PageWrapper title={i18next.t('agency.home.employee_list_title')} component={EmployeeList} />}
          />
          <Route
            path="/agencies/:agencyId/agency_employees/:agencyEmployeeId"
            element={<PageWrapper title={i18next.t('agency.employee_profile.title')} component={EmployeeProfile} />}
          />
        </Route>
      </Route>

      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<PageWrapper title={i18next.t('agency.sign_in.title')} component={Login} />} />
        <Route
          path="/agent/password/new"
          element={<PageWrapper title={i18next.t('agency.forgot_password.title')} component={ForgotPassword} />}
        />
        <Route
          path="/agent/password/edit"
          element={<PageWrapper title={i18next.t('agency.reset_password.title')} component={ResetPassword} />}
        />
      </Route>
    </Route>
  )
)

const App = () => {
  useSetupHubSpotChat()
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="bottom-center" containerStyle={{ bottom: '40px' }} />
      <AgentProvider>
        <RouterProvider router={router} />
      </AgentProvider>
    </QueryClientProvider>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root')
  if (container) {
    i18next.init({
      lng: 'fr',
      resources: window.translation_keys,
    })
    daysjsLocale('fr')

    const root = createRoot(container)
    root.render(<App />)
  }
})
